<template>
  <div class="">
    <header-portrate />
    <exports-print ref="exportbar" />
    <div>
    <bread-crumbs :items='items'/>
    <TabsComp :tabs="tabs" />
    <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
      <v-row class="mt-10">
        <v-col
          cols="12"
          md="10"
          sm="12">
          <form autocomplete="off">
          <v-row>
            <v-col cols="12" md="2" sm="12">
              <label>{{lang.start_date}}</label>
              <b-input-group>
                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
              </b-input-group>
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <label>{{lang.end_date}}</label>
              <b-input-group>
                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
              </b-input-group>
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <label>{{lang.mobile}}</label>
              <b-input-group>
                <b-form-input class="inborder" v-model="mobile" style="background:#c3d9ff;" />
              </b-input-group>
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <label>{{lang.cardid}}</label>
              <b-input-group>
                <b-form-input class="inborder" v-model="cardid" style="background:#c3d9ff;" />
              </b-input-group>
            </v-col>
            <v-col cols="12" md="2" sm="12" v-if="$store.state.licenseType.cars">
              <label>{{lang.plate_number}}</label>
              <b-input-group>     
                <b-form-input class="inborder" v-model="plate_number" style="background:#c3d9ff;" />
              </b-input-group>
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <label></label>
              <b-input-group style="margin-top:-5px">
                <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getCurrentCards()">{{lang.search}}</b-button>
              </b-input-group>
            </v-col>
          </v-row>
          <b-input-group class="mt-3" style="display:none;">
            <b-form-input placeholder="Start Date" onfocus="(this.type='date')" style="direction:rtl;border:1px solid #000;border-radius: 0;" type="date" v-model="sdate" :title="lang.start_date"></b-form-input>
            <b-form-input placeholder="End Date" onfocus="(this.type='date')" style="border:1px solid #000" type="date" v-model="edate" :title="lang.end_date"></b-form-input>
            <b-form-input :placeholder="lang.mobile"  style="border:1px solid #000" v-model="mobile"></b-form-input>
            <b-form-input :placeholder="lang.plate_number" style="border:1px solid #000" v-model="plate_number"></b-form-input>
            <b-input-group-append style="border:1px solid #000">
              <!-- <b-button variant="light" style="background:gold;"  @click="getCurrentCards()"><i class="fas fa-search"></i></b-button> -->
              <b-button variant="light" style="background:black;color:#fff"  @click="getCurrentCards()">{{lang.search}}</b-button>
            </b-input-group-append>
            
          </b-input-group>
        </form>
        </v-col>
        <v-col cols="12" md="2" sm="12" class="text-end pt-7">
          <b-button
              v-b-toggle.sidebar-1
              class="btn-sm btn "
              style="margin-top:9px;width:165px;background:transparent;border:none !important;margin-left:-7px !important;"
              id="multiply-button" 
            >
              <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add_maint_request}}</div>
          </b-button>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :style="`direction: `+lang.dir+`;`">
          <v-data-table
              :headers="headers"
              :items="tablerows"
              :search="search"
              :items-per-page="15"
              :page.sync="page"
              width="100%"
              id="MainTable"
              hide-default-footer
              @page-count="pageCount = $event"
              fixed-header
          >
            <template v-slot:item="row">
              <tr>
                <td style="width:50px">{{ row.item.cardid }}</td>
                <td nowrap>{{ row.item.customer }}</td>
                <td>{{ row.item.mobile }}</td>
                <td>{{ row.item.plate_number }}</td>
                <td>{{ row.item.car_model }}</td>
                <td nowrap>{{ row.item.date_in }}</td> 
                <td nowrap>{{ row.item.date_out }}</td>
                <td nowrap>{{ row.item.parts }}</td>
                <td style="background:lightgreen !important">{{ row.item.ftotal }}</td>
                <td>{{ row.item.vat }}</td>
                <td style="background:lightgreen !important">{{ row.item.paid }}</td>
                <td style="background:lightgreen !important">{{ row.item.remaining }}</td>
                <td  style="background:lightgreen !important;width:75px !important;">{{ row.item.expen }}</td>
                <td :style="`border:2px solid #fff !important;text-align:center;background:`+row.item._statusbg+`;color:`+row.item._statuscolor+` !important;width:100px !important;font-weight:bolder !important`">{{ row.item._status }}</td>
                <td style="margin-left: 5px;width:60px;background:blue;border-bottom:2px solid #fff !important;border-top:2px solid #fff !important;">
                  <v-btn style="font-size:0.2em;background:blue;width:100%;color:#fff;height:25px !important;margin-left:5px;" v-b-toggle.view_card @click="ViewItem(row.item)">{{lang.view}}</v-btn>
                </td>
              </tr>
            </template>
            <template slot="body.append">
                    <tr class="black--text tfoot">
                        <td class="backEmpty"></td>
                        <td class="backEmpty"></td>
                        <td class="backEmpty"></td>
                        <td class="backEmpty"></td>
                        <td class="backEmpty"></td>
                        <td class="backEmpty"></td>
                        <td class="backBlack" style="font-size:1.2em;text-align:center;">{{lang.totals}}</td>
                        <td class="backBlack" style="font-size:1.2em;text-align:center;">{{ sumField('parts') }}</td>
                        <td class="backGreen" style="font-size:1.2em;text-align:center;">{{ sumField('ftotal') }}</td>
                        <td class="backRed" style="font-size:1.2em;text-align:center;">{{ sumField('vat') }}</td>
                        <td class="backGreen" style="font-size:1.2em;text-align:center;">{{ sumField('paid') }}</td>
                        <td class="backRed" style="font-size:1.2em;text-align:center;">{{ sumField('remaining') }}</td>
                        <td class="backRed" style="font-size:1.2em;text-align:center;">{{ sumField('expen') }}</td>
                        <td class="backEmpty"></td>
                        <td class="backEmpty"></td>
                    </tr>
            </template>
          </v-data-table>
          
        </v-col>
        <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">

          <v-container>
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                value="10"
                :length="pageCount"
                color="#000"
              ></v-pagination>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-row>
    <CreateNewCard ref="createnew" />

    </div>
    <view-card ref="viewCard" />
    <Footer />
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import CreateNewCard from "@/components/CreateNewCard.vue"
  import BreadCrumbs from '@/components/breadCrumbs.vue'
  import HeaderPortrate from '@/components/Header-Portrate.vue'
  import Footer from '@/components/Footer.vue'
  import ViewCard from '@/components/view-card.vue'
  import ExportsPrint from '@/components/exports-print.vue'
  import TabsComp from '@/components/tabsComp.vue'
  export default Vue.extend({
    name: 'Home',

    components: {
      CreateNewCard,
        BreadCrumbs,
        HeaderPortrate,
        Footer,
        ViewCard,
        ExportsPrint,TabsComp
    },
    data() {
      return {
        cardid: '',
        selected:1,
        plate_number:'',
        dBgcolor:'',
        search: '',
        drow: false,
        page: 1,
        pageCount:0,
        // sdate: '',
        // edate: '',
        mobile: '',
        excel_fields:{},
        
        tablerows: [
          
        ],
        ishome: true,
        loading: false,
        sd: {
          day: 1,
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear() - 1,
        },
        ed: {
          day: (new Date()).getDay() + 1,
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
        }
      }
    },
    created() {
      this.getCurrentCards();
      setInterval(() => {this.getCurrentCards();},20000)
      this.getExcelHead();
      setTimeout(() => this.iamin(),100);
    },
    methods: {
      preparedata(){
        let t = this.headers;
        let data = this.tablerows;
        let hdata = [];
        let exdata = [];
        for(let i=0;i<t.length;i++){
          hdata.push(t[i].text);
        }
        exdata.push(hdata);
        for(let k=0;k<data.length;k++){
          let x = [];
            x.push(data[k].cardid);
            x.push(data[k].customer);
            x.push(data[k].mobile);
            x.push(data[k].plate_number);
            x.push(data[k].car_model);
            x.push(data[k].date_in);
            x.push(data[k].date_out);
            x.push(data[k].parts);
            x.push(data[k].ftotal);
            x.push(data[k].vat);
            x.push(data[k].paid);
            x.push(data[k].remaining);
            x.push(data[k].expen);
            x.push(data[k]._status);
            exdata.push(x);
        }
        this.$refs.exportbar.exportdata = exdata;
        this.$refs.exportbar.sdate = this.sdate;
        this.$refs.exportbar.edate = this.edate;
        if(this.selected == 1)
            this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_new;
        if(this.selected == 4)
            this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_late;
        if(this.selected == 3)
            this.$refs.exportbar.exporttitle = this.lang.ment_req_cards_closed;
        this.$refs.exportbar.palte_number = this.plate_number;
        this.$refs.exportbar.contact_number = this.mobile;
      },
      resetAll(){
        this.$refs.createnew.resetALl();
      },
      iamin(){
        if("params",typeof this.$route.params.select !== 'undefined'){
          this.changeBck(this.$route.params.doit);
          this.selected = this.$route.params.select;
          this.getCurrentCards();
        }else{
          this.changeBck(1)
        }
      },
      changeBck(i){
        // this.sdate = '';
        // this.edate = '';
        // this.mobile = '';
        // this.plate_number = '';
        // if(i == 1){
        //   document.getElementById('underwork').style.backgroundColor = '#000000';
        //   document.getElementById('underwork').style.color = '#fff';
        //   document.getElementById('latecard').style.backgroundColor = '#eee';
        //   document.getElementById('latecard').style.color = '#000';
        //   document.getElementById('closedcard').style.backgroundColor = '#eee';
        //   document.getElementById('closedcard').style.color = '#000';
        //   document.getElementById('underwork').innerHTML = this.lang.new + '&nbsp;&nbsp;<i class="fas fa-arrow-down"></i>';
        //   document.getElementById('closedcard').innerHTML = this.lang.closed;
        //   document.getElementById('latecard').innerHTML = this.lang.late;
        // }else if(i == 2){
        //   document.getElementById('underwork').style.backgroundColor = '#eee';
        //   document.getElementById('underwork').style.color = '#000';
        //   document.getElementById('latecard').style.backgroundColor = '#eee';
        //   document.getElementById('latecard').style.color = '#000';
        //   document.getElementById('closedcard').style.backgroundColor = '#000000';
        //   document.getElementById('closedcard').style.color = '#fff';
        //   document.getElementById('underwork').innerHTML = this.lang.new;
        //   document.getElementById('closedcard').innerHTML = this.lang.closed + '&nbsp;&nbsp;<i class="fas fa-arrow-down"></i>';
        //   document.getElementById('latecard').innerHTML = this.lang.late;
        // }else if(i == 3){
        //   document.getElementById('underwork').style.backgroundColor = '#eee';
        //   document.getElementById('underwork').style.color = '#000';
        //   document.getElementById('latecard').style.backgroundColor = '#000000';
        //   document.getElementById('latecard').style.color = '#fff';
        //   document.getElementById('closedcard').style.backgroundColor = '#eee';
        //   document.getElementById('closedcard').style.color = '#000000';
        //   document.getElementById('underwork').innerHTML = this.lang.new;
        //   document.getElementById('closedcard').innerHTML = this.lang.closed ;
        //   document.getElementById('latecard').innerHTML = this.lang.late+ '&nbsp;&nbsp;<i class="fas fa-arrow-down"></i>';
        // }
      },
      getExcelHead(){
        const head = this.headers;
        
        // for(var i=0;i< head.length;i++){
        //   const x = head[i].text;
        //   const y = head[i].value;
        //   this.excel_fields[x] = y;
        //   // console.log("xx",this.excel_fields[x]);
        // }
        
      },
      gotoDaily(){
        this.$router.push({path: '/reports/daily-reports'})
      },
      PrintPage(){
        window.print();
      },
      sumField(key) {
        let x = this.tablerows.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
        x = +x *100;
        x = (Math.round(x)/100);
        if(x != 0) return x;
        else return 0;
      },
      ViewItem(item){
        this.$refs.viewCard.cardid = item.cardid;
        this.$refs.viewCard.card = item;
        this.$refs.viewCard.getCard();
        this.$refs.viewCard.updateChileds();
        this.$refs.viewCard.calckInv();

      },
      getCurrentCards(){
        // console.log(this.loading);
        
        let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
        const path = this.$router.currentRoute.path;
        let post = new FormData();
        if(path == "/home1"){
          this.selected = 3;
        }
        post.append('type','getCardslist'); 
        post.append('auth',cook);
        post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
        let data = {
          datatype: 'all',
          sdate: this.sdate,
          edate: this.edate,
          mobile: this.mobile,
          plate_number: this.plate_number,
        };
        post.append('data[datatype]',data.datatype);
        post.append('data[sdate]',data.sdate);
        post.append('data[edate]',data.edate);
        post.append('data[mobile]',data.mobile);
        post.append('data[plate_number]',this.plate_number);
        // post.append('data[status]',this.selected);
        // this.tablerows = [];
        this.loading = true;
        axios.post(
          this.$store.state.SAMCOTEC.r_path,
          post
        ).then((response) => {
          const res = response.data;
          // console.log(res);
          this.tablerows = res.results.data;
          this.loading = false;
        })
      },
      ExportToExcel(){
        //
      },
      
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },
        headers: function() {
          return [
            {
              text: "#",
              align: 'center',
              filterable: false,
              value: 'cardid',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: this.lang.customer_name,
              align: 'center',
              filterable: false,
              value: 'customer',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: this.lang.mobile,
              align: 'center',
              filterable: false,
              value: 'mobile',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: this.lang.plate_number,
              align: 'center',
              filterable: false,
              value: 'plate_number',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: this.lang.car_model,
              align: 'center',
              filterable: false,
              value: 'car_model',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            // {
            //   text: this.lang.car_color,
            //   align: 'center',
            //   filterable: false,
            //   value: 'car_color',
            //   sortable: false,
            // },
            {
              text: this.lang.received,
              align: 'center',
              filterable: false,
              value: 'sdate',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: this.lang.delivered,
              align: 'center',
              filterable: false,
              value: 'ddate',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: this.lang.inventory,
              align: 'center',
              filterable: false,
              value: 'parts',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: this.lang.total,
              align: 'center',
              filterable: false,
              value: 'ftotal',
              sortable: false,
              class: 'pa-0 backGreen'
            },
            {
              text: this.lang.vat,
              align: 'center',
              filterable: false,
              value: 'vat',
              sortable: false,
              class: 'pa-0 backRed'
            },
            {
              text: this.lang.paid,
              align: 'center',
              filterable: false,
              value: 'paid',
              sortable: false,
              class: 'pa-0 backGreen'
            },
            {
              text: this.lang.remain,
              align: 'center',
              filterable: false,
              value: 'remaining',
              sortable: false,
              class: 'pa-0 backRed'
            },
            {
              text: this.lang.expenses,
              align: 'center',
              filterable: false,
              value: 'expen',
              sortable: false,
              class: 'pa-0 backRed'
            },
            {
              text: this.lang.status,
              align: 'center',
              filterable: false,
              value: '_status',
              sortable: false,
              class: 'pa-0 backBlack'
            },
            {
              text: this.lang.action,
              align: 'center',
              filterable: false,
              value: '',
              sortable: false,
              class: 'pad-0 backBlack'
            }
          ]
        },
      items:function() {
          let title = this.$store.state.hometitle;
          const lang = this.$cookies.get(this.$store.state.LangCooki);
          if(lang == "en"){
            title = this.$store.state.hometitle_en;
          }
          return {
            text: title,
            disabled: true,
            href: '/home',
          }
      },
      xstatus: function() {
        return [
          {
              value: 1,
              text: this.lang.inprogress
          },
          {
              value: 3,
              text: this.lang.closed
          },
          {
              value: 2,
              text: this.lang.not_invoiced
          },
        ]
      },
      tabs: function() {
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
      }
    },
  })
</script>
<style>
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th, tfoot th, .tfoot th{
  background: #c3d9ff !important;
  color:#000 !important;
  border:1px solid #000 !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #000;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #000;
}
.close svg {
    width: 74px !important;
    height: 34px !important;
    color: #FFF;
    background: red;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
input{
  box-shadow: none !important;
}
button:active,button:focus{
  box-shadow: none !important;
}
._mybtn{
  font-size:.8rem;
  border:0px solid #fff;
  width:100%;
}
._mybtn2{
  font-size:.8rem;
  background:rgb(15, 212, 230) !important;
  border:0px solid #000;
  width:100%;
}
._mybtn4{
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
._mybtn3{
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
._mybtn1{
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
td,th{
      height: 30px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px !important;
}
</style>


<template>
    <div id="pageHeaders" class="clearMe pa-2 text-start">
        <!-- <img class="imglogo" style="" :src="`${publicPath}/header-logo-bg.png`"> -->
        <div class="gbackground m-2 mt-0" style="display:none;padding-top:10px !important;">
               &nbsp; {{ sitetitle }}
               <span class="logoutclass">
                   <i @click="$cookies.remove($COOKIEPhase),$cookies.remove($store.state.COOKIEPhase),cook = 'null', $router.push({name: 'Logout'})" class="fas fa-sign-out-alt" style="font-size:1.7em;cursor:pointer;"></i>
               </span>
        </div>
            <v-card
                class="d-flex  text-center"
                color="#d7b100"
                >
                    <v-card
                        v-for="(item,index) in topMenuO"
                        :key="index"
                        class="pa-1"
                        style="margin-inline-end:5px;padding:25px 1px !important; border:1px solid #000"
                        outlined
                        :style="`background:`+item.bgcolor+` !important;font-weight:bold;width:130px;color:`+item.color"
                        @click="changeMenu(item.index,item.href,item.to)"
                    >
                        {{item.text}}
                    </v-card>
                    <v-card v-if="showExit"
                        class="pa-1"
                        style="margin-inline-end:5px;padding:25px 1px !important;"
                        outlined
                        :style="`background:red !important;font-weight:bold;width:130px;color:#FFF`"
                        @click="gotoHome(), $router.push({path: '/logout'})"
                    >
                        {{ lang.logout }}
                    </v-card>
            </v-card>
        
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
           sitetitle: ' ',
           cook: '',
           publicPath: process.env.BASE_URL+'img/',
           showHeader: true,
           topMenuO: [],
           showExit: false,
        }
    },
    created() {
        // if(this.showHeader){
            this.getSettings();
            this.getUser();
            setInterval(() => {this.drawMenu()},50)
            setTimeout(() => {this.showExit = false;this.showExit = true;},70)
            this.drawMenu()
        // }
    },

    methods: {
        gotoHome(){
            localStorage.setItem('currentMenu',1);
        },
        changeMenu(index ,href,to){
            localStorage.setItem('currentMenu',index);
            if(href != this.$route.path){
                if(this.$store.state.licenseType.isCar){
                    this.$router.push({path: to})
                }else{
                    this.$router.push({path: href})
                }
                
            }
        },
        getSettings(){
            const post = new FormData();
            post.append("type" , "getSettings");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.error.number != 200){
                    this.$router.push('/logout');
                    return false;
                }
                this.$store.state.settings = res.results.data;
            })
        },
        getUser(){
            const post = new FormData();
            post.append("type" , "getUser");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.length != 0){
                    this.$store.state.group_id = res.results.data.group_id;
                }
            })
        },
        drawMenu(){
            this.topMenuO = this.TopMenu
        }
    },
    computed: {
        lang: {
            get: function(){
                if(this.$cookies.get(this.$store.state.LangCooki) != null){
                    if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                        return this.$store.state.lang.en;
                    }else{
                        return this.$store.state.lang.ar;
                    }
                }
                else if(this.$store.state.deflang == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            },
            set: function(newValue){
                return newValue
            }
        },
        TopMenu:  function(){
            let t = [];
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            let currentMenu = menu.small; 
            
            Object.values(currentMenu).forEach(value =>{
                
                if(this.lang.langname == 'ar'){
                    value.main.text = value.main.arname
                }else{
                    value.main.text = value.main.enname
                }
                const cmenu = localStorage.getItem('currentMenu');
                
                if(cmenu == value.main.index){
                    value.main.bgcolor = '#ffffff';
                    value.main.color = '#000000';
                }else if(cmenu == null){
                    if(value.main.index == 1){
                        value.main.bgcolor = '#ffffff';
                        value.main.color = '#000000';
                        localStorage.setItem('currentMenu',1);
                        this.changeMenu(1,value.main.href)
                    }
                }else{
                    value.main.bgcolor = '#000000';
                    value.main.color = '#ffffff';
                }
                for(let i =0; i< value.main.groupid.length;i++){
                    if(value.main.groupid[i] == this.$store.getters['state'].group_id){
                        t.push(value.main)
                    }
                }
            });
            // console.log("topMenu",t);
            return t;
        },
        groupid: function() {
            return this.$store.state.group_id;
        },
        license: function(){
            return this.$store.getters['state'].licenseType.activemenu
        }
    },
    
}
</script>
<style>
.gbackground{
    position: relative;
    z-index: 10;
    background: rgb(215,177,0);
    background: linear-gradient(
    -45deg, rgba(215,177,0,1) 0%, 
    #d7b100 88%, rgba(0,0,0,1) 88%, 
    rgba(0,0,0,1) 89%, rgba(215,177,0,1) 89%, 
    rgba(215,177,0,1) 91%, rgba(0,0,0,1) 91%, 
    rgba(0,0,0,1) 92%, rgba(215,177,0,1) 92%, 
    rgba(215,177,0,1) 94%, rgba(0,0,0,1) 94%, 
    rgba(0,0,0,1) 95%, rgba(215,177,0,1) 95%, 
    rgba(215,177,0,1) 100%
    );
    padding:0px !important;
}
.logoutclass{
    color:#FFF;
    position: absolute;
    left:5px;
    top: 10px;
}
.clearMe{
    position: relative;
    background: rgb(215,177,0);
    direction:rtl;
    color:#FFF;
    padding:2px 10px 10px 5px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
.imglogo{
    position: absolute;
    top:2px;
    left:3px;
    z-index: 1000;
    height:85px;
    border: 2px solid #000;
}
td, th{
    border:1px solid #000 !important;
}
</style>